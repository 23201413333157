@font-face {
    font-family: "Montserrat SemiBold";
    src: local("Montserrat SemiBold"), url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"), url("../fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat Bold";
    src: local("Montserrat Bold"), url("../fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat Medium";
    src: local("Montserrat Medium"), url("../fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat Light";
    src: local("Montserrat Light"), url("../fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Courier";
    src: local("Courier"), url("../fonts/CourierPrime-Regular.ttf") format("truetype");
}

@font-face {
    font-family: Square;
    src: local("Square"), url("../fonts/EuclidSquare-Medium.woff") format("truetype");
}
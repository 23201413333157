@import "../assets/styles/fonts.scss";

* {
    outline: none;
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Square';
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}

a {
    text-decoration: none;
}

input[type="number"] {
    -moz-appearance: textfield !important;
}

p {
    margin: 0;
}

*::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
*::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
    background-color: #aaa !important;
    border-radius: 10px;
}

.Toastify__toast-container--top-right {
    top: 75px
}

.Toastify__progress-bar--error,
.Toastify__progress-bar--info {
    background-color: #FFF;
}

.Toastify__toast-theme--light {
    font-family: 'Square';
}

.MuiPaper-root {
    overflow-x: auto !important;
}